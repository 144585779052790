import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(private title: Title, private _meta: Meta) {}

  updateMetas(metas: any) {
    if (metas) {
      const envCode = environment.code === "PG" ? "Pagovoy" : "Laytrip";

      // Update meta tags (title and meta tags)
      if (metas.meta) {
        Object.entries(metas.meta).forEach(([key, value]: [string, string]) => {
          const content = value.replace("Laytrip", envCode);

          if (key === "title") {
            this.title.setTitle(content);
          } else {
            this._meta.updateTag({ name: key, content });
          }
        });
      }

      // Update social meta tags
      if (metas.social) {
        Object.entries(metas.social).forEach(
          ([key, value]: [string, string]) => {
            const content = value.replace("Laytrip", envCode);
            this._meta.updateTag({ property: key, content });
          }
        );
      }

      // Update Open Graph (OG) meta tags
      if (metas.og) {
        Object.entries(metas.og).forEach(([key, value]: [string, string]) => {
          const content = value.replace("Laytrip", envCode);
          this._meta.updateTag({ property: key, content });
        });
      }
    }
  }
}
